import React, { useState, useEffect } from 'react';
import { Link, graphql, useStaticQuery } from "gatsby";

const MenuPanel = (props) => {

  const data = useStaticQuery(graphql`
    query MenuPanelQuery {
      site {
        siteMetadata {
          instaStoreUrl
          facebookUrl
          whatsappUrl
          tokopediaUrl
          shopeeUrl
          address
          email
          waNum
          author {
            name
            summary
          }
        }
      }
      linktree: markdownRemark(frontmatter: { id: { eq: "linktree" } }) {
        frontmatter {
          id
          links {
            label
            url
          }
        }
      }
      markdownRemark(frontmatter: { id: { eq: "home" } }) {
        frontmatter {
          logo
          logoHorizontal
          pictureLogo
          brands {
            id
            name
          }
          tags {
            id
            name
          }
        }
      }
    }
  `);

  return (
    <div class="menu-panel">
  <ul class="menu-panel__mobile-bar list-unstyled d-md-none">
    <li class="mobile-bar-item">
      <Link className="mobile-bar-item__header" to="/products">Home</Link>
    </li>
    <li class="mobile-bar-item">
      <a class="mobile-bar-item__header collapsed" data-toggle="collapse" href="#mobile_collapse_1" role="button" aria-expanded="false" aria-controls="mobile_collapse_1">
        Products
        <span class="main-nav__toggle">&nbsp;</span>
      </a>
      <div id="mobile_collapse_1" class="collapse mobile-bar-item__body">

        <nav class="mobile-nav">
          <ul class="mobile-nav__list">
            <li class="">
              <Link to="/products">All Products</Link>
            </li>
            <li class="">
              <Link to="/products/tag=gchair">Gaming Chair</Link>
            </li>
          </ul>
        </nav>

      </div>
    </li>
    <li class="mobile-bar-item">
      <a class="mobile-bar-item__header collapsed" data-toggle="collapse" href="#mobile_collapse_2" role="button" aria-expanded="false" aria-controls="mobile_collapse_2">
        Articles
        <span class="main-nav__toggle">&nbsp;</span>
      </a>
      <div id="mobile_collapse_2" class="collapse mobile-bar-item__body">

        <nav class="mobile-nav">
          <ul class="mobile-nav__list">
          <li class=""><Link to="/blogs">All Articles</Link></li>
          <li class=""><Link to="/blogs">Kenapa Kita?</Link></li>
            <li class=""><Link to="/blogs">Ergonomics</Link></li>
          </ul>
        </nav>

      </div>
    </li>
    <li class="mobile-bar-item">
      <Link className="mobile-bar-item__header" to="/about">About Us</Link>
    </li>
    <li class="mobile-bar-item">
      <a class="mobile-bar-item__header collapsed" data-toggle="collapse" href="#mobile_collapse_3" role="button" aria-expanded="false" aria-controls="mobile_collapse_3">
        Contact Us
        <span class="main-nav__toggle">&nbsp;</span>
      </a>
      <div id="mobile_collapse_3" class="collapse mobile-bar-item__body">
        <ul class="social-menu social-menu--mobile-bar">
          <li class="">
            <Link to="/contact"><span>Contact Page</span></Link>
          </li>
          <li><a href={data.site.siteMetadata.whatsappUrl} target="_blank"><span>Whatsapp</span></a></li>
          <li><a href={data.site.siteMetadata.instaStoreUrl} target="_blank"><span>Instagram</span></a></li>
        </ul>
        <div class="mobile-bar-item__inner">
          <ul class="list-unstyled">
            <li class="info-box">
              <div class="info-box__label">Pertanyaan atau Kerjasama</div>
              <div class="info-box__content"><a href={`mailto:${data.site.siteMetadata.instaStoreUrl}`}>apexwarrior.id<span class="color-primary">@</span>gmail.com</a></div>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li class="mobile-bar-item mobile-bar-item--partners">
      <a id="mobile_collapse_4h" class="mobile-bar-item__header collapsed" data-toggle="collapse" href="#mobile_collapse_4" role="button" aria-expanded="false" aria-controls="mobile_collapse_4">
        Our Brands
        <span class="main-nav__toggle">&nbsp;</span>
      </a>
      <div id="mobile_collapse_4" class="collapse mobile-bar-item__body">
        <div class="mobile-bar-item__inner">
          <ul class="widget-partners-mobile-carousel">
            <li>
              <img src="/assets/img/samples/partner-carousel-img-01.png" alt=""/>
            </li>
            <li>
              <img src="/assets/img/samples/partner-carousel-img-02.png" alt=""/>
            </li>
            <li>
              <img src="/assets/img/samples/partner-carousel-img-03.png" alt=""/>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
  <div class="menu-panel__top-bar">
    {/* <div class="top-bar-filter">
      <label class="top-bar-filter__label">Competitions</label>
      <select class="cs-select">
        <option value="all" selected>All Competitions</option>
        <option value="x2018">Xenowatch 2018 Tournament</option>
        <option value="xwleague">Xenowatch West League</option>
        <option value="xeleague">Xenowatch East League</option>
        <option value="xusacup">Xenowatch USA Cup</option>
      </select>
    </div>
    <ul class="top-bar-carousel">
      <li class="top-bar-carousel__headline x2018">
        <span>Xenowatch 2018 Tournament</span>
      </li>
      <li class="top-bar-carousel__item x2018">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">2</span>
              </div>
              <div class="match-result match-result--live">
                <span class="match-result__label">25:42</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-2" role="group">
                  <img src="/assets/img/samples/logo-rhinos-30.png" srcset="assets/img/samples/logo-rhinos-30@2x.png 2x" alt="RRH Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">RRH</div>
                    <div class="match-team__country ">JPN</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">1</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">01/12</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="top-bar-carousel__item x2018">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-5" role="group">
                  <img src="/assets/img/samples/logo-eagles-30.png" srcset="assets/img/samples/logo-eagles-30@2x.png 2x" alt="FEG Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">FEG</div>
                    <div class="match-team__country ">FRA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">4</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">Final</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">3</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">01/09</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="top-bar-carousel__item x2018">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">1</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">Final</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-3" role="group">
                  <img src="/assets/img/samples/logo-bulls-30.png" srcset="assets/img/samples/logo-bulls-30@2x.png 2x" alt="AGB Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">AGB</div>
                    <div class="match-team__country ">SPA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">3</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">01/05</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="top-bar-carousel__item x2018">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-4" role="group">
                  <img src="/assets/img/samples/logo-panthers-30.png" srcset="assets/img/samples/logo-panthers-30@2x.png 2x" alt="ICP Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">ICP</div>
                    <div class="match-team__country ">POR</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">0</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">Final</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">5</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">01/02</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>


      <li class="top-bar-carousel__headline xwleague">
        <span>Xenowatch West League</span>
      </li>
      <li class="top-bar-carousel__item xwleague">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">1</span>
              </div>
              <div class="match-result match-result--live">
                <span class="match-result__label">12:33</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-2" role="group">
                  <img src="/assets/img/samples/logo-rhinos-30.png" srcset="assets/img/samples/logo-rhinos-30@2x.png 2x" alt="RRH Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">RRH</div>
                    <div class="match-team__country ">JPN</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">0</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">01/12</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="top-bar-carousel__item xwleague">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-4" role="group">
                  <img src="/assets/img/samples/logo-panthers-30.png" srcset="assets/img/samples/logo-panthers-30@2x.png 2x" alt="ICP Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">ICP</div>
                    <div class="match-team__country ">POR</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">0</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">Final</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">3</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">01/07</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="top-bar-carousel__item xwleague">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-5" role="group">
                  <img src="/assets/img/samples/logo-eagles-30.png" srcset="assets/img/samples/logo-eagles-30@2x.png 2x" alt="FEG Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">FEG</div>
                    <div class="match-team__country ">FRA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">5</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">Final</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">2</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">01/04</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="top-bar-carousel__item xwleague">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">1</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">Final</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-3" role="group">
                  <img src="/assets/img/samples/logo-bulls-30.png" srcset="assets/img/samples/logo-bulls-30@2x.png 2x" alt="AGB Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">AGB</div>
                    <div class="match-team__country ">SPA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">3</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">01/03</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>


      <li class="top-bar-carousel__headline xeleague">
        <span>Xenowatch East League</span>
      </li>
      <li class="top-bar-carousel__item xeleague">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">4</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">Final</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-2" role="group">
                  <img src="/assets/img/samples/logo-rhinos-30.png" srcset="assets/img/samples/logo-rhinos-30@2x.png 2x" alt="RRH Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">RRH</div>
                    <div class="match-team__country ">JPN</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">1</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">12/18</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="top-bar-carousel__item xeleague">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">4</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">Final</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-3" role="group">
                  <img src="/assets/img/samples/logo-bulls-30.png" srcset="assets/img/samples/logo-bulls-30@2x.png 2x" alt="AGB Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">AGB</div>
                    <div class="match-team__country ">SPA</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">3</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">12/11</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="top-bar-carousel__item xeleague">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-4" role="group">
                  <img src="/assets/img/samples/logo-panthers-30.png" srcset="assets/img/samples/logo-panthers-30@2x.png 2x" alt="ICP Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">ICP</div>
                    <div class="match-team__country ">POR</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">0</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">Final</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">2</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">12/09</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>


      <li class="top-bar-carousel__headline xusacup">
        <span>Xenowatch USA Cup</span>
      </li>
      <li class="top-bar-carousel__item xusacup">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">1</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">Final</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-3" role="group">
                  <img src="/assets/img/samples/logo-bulls-30.png" srcset="assets/img/samples/logo-bulls-30@2x.png 2x" alt="AGB Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">AGB</div>
                    <div class="match-team__country ">SPA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">3</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">11/17</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="top-bar-carousel__item xusacup">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-5" role="group">
                  <img src="/assets/img/samples/logo-eagles-30.png" srcset="assets/img/samples/logo-eagles-30@2x.png 2x" alt="FEG Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">FEG</div>
                    <div class="match-team__country ">FRA</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">2</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">Final</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">1</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">11/11</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="top-bar-carousel__item xusacup">
        <div class="match-score-inline">
          <div class="match-score-inline__body">
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-4" role="group">
                  <img src="/assets/img/samples/logo-panthers-30.png" srcset="assets/img/samples/logo-panthers-30@2x.png 2x" alt="ICP Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">ICP</div>
                    <div class="match-team__country ">POR</div>
                </figcaption>
              </figure>
              <div class="match-result match-result--winner-right">
                <span class="match-result__score">3</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">Final</span>
              </div>
            </div>
            <div class="match-score-inline__body-inner">
              <figure class="match-team" role="group">
                <figure class="match-team-logo match-team-logo--team-1" role="group">
                  <img src="/assets/img/samples/logo-necromancers-30.png" srcset="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo"/>
                </figure>
                <figcaption>
                  <div class="match-team__name">NCR</div>
                    <div class="match-team__country ">USA</div>
                </figcaption>
              </figure>
              <div class="match-result">
                <span class="match-result__score">2</span>
              </div>
              <div class="match-result">
                <span class="match-result__label">11/06</span>
              </div>
            </div>
          </div>
          <div class="match-score-inline__footer">
            <ul class="match-stats-links">
              <li>
                <a href="matches-overview-1.html">
                  <svg role="img" class="df-icon df-icon--match-overview">
                    <use href="/assets/img/necromancers.svg#match-overview"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-stats-1.html">
                  <svg role="img" class="df-icon df-icon--match-stats">
                    <use href="/assets/img/necromancers.svg#match-stats"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-lineups-1.html">
                  <svg role="img" class="df-icon df-icon--match-lineups">
                    <use href="/assets/img/necromancers.svg#match-lineups"/>
                  </svg>
                </a>
              </li>
              <li>
                <a href="matches-replay.html">
                  <svg role="img" class="df-icon df-icon--match-replay">
                    <use href="/assets/img/necromancers.svg#match-replay"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul> */}

  </div>
  <div class="menu-panel__content d-none d-md-flex">
    <div class="menu-panel__navigation">
      <div id="dl-menu" class="dl-menuwrapper">

        <ul class="dl-menu dl-menuopen">
          <li class="">
            <a href="home.html">Home</a>
          </li>
          <li class="">
            <a href="#">Products</a>
            <ul class="dl-submenu">
              <li class="">
                <Link to="/products">All Products</Link>
              </li>
              <li class="">
                <Link to="/products">Gaming Chairs</Link>
              </li>
            </ul>
          </li>
          <li class=""><a href="#">Articles</a>
            <ul class="dl-submenu">
              <li class=""><Link to="/blog/whyus">Kenapa Kita?</Link></li>
              <li class=""><Link to="/blog/ergonomics">Ergonomics</Link></li>
              <li class=""><Link to="/blogs">All Articles</Link></li>
            </ul>
          </li>
          <li class="">
            <Link to="/about">About Us</Link>
          </li>
          <li class="">
            <Link to="/contact">Contact Us</Link>
          </li>
          </ul>
      </div>
    </div>
    <div class="menu-panel__widget-area">
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-5">

        <section class="widget widget-contact-info">
            <h5 class="widget__title">Contact Info</h5>
            <div class="widget__content">
              <p>
                Untuk pertanyaan, atau ingin berkolaborasi, silahkan hubungi kami lewat Whatsapp atau Email tertera dibawah.
              </p>
              <div class="info-box">
                <div class="info-box__label">General inquiries</div>
                <div class="info-box__content"><a href={`mailto:${data.site.siteMetadata.email}`}>apexwarrior.id<span class="color-primary">@</span>gmail.com</a></div>
              </div>
              <ul class="social-menu social-menu--default">
                <li><a href={data.site.siteMetadata.whatsappUrl} target="_blank"></a></li>
                <li><a href={data.site.siteMetadata.instaStoreUrl} target="_blank"></a></li>
                <li><a href={data.site.siteMetadata.tokopediaUrl} target="_blank"></a></li>
                <li><a href={data.site.siteMetadata.shopeeUrl} target="_blank"></a></li>
              </ul>
            </div>
          </section>

        </div>
      </div>
      <div class="row">
        <div class="col-md-12">

          <section class="widget widget-partners-carousel">
            <h5 class="widget__title">Our Brands</h5>
            <div class="widget__content">
              <ul>
                <li>
                  <img src="/assets/img/samples/partner-carousel-img-01.png" alt=""/>
                </li>
                <li>
                  <img src="/assets/img/samples/partner-carousel-img-02.png" alt=""/>
                </li>
                <li>
                  <img src="/assets/img/samples/partner-carousel-img-03.png" alt=""/>
                </li>
                <li>
                  <img src="/assets/img/samples/partner-carousel-img-01.png" alt=""/>
                </li>
                <li>
                  <img src="/assets/img/samples/partner-carousel-img-02.png" alt=""/>
                </li>
                <li>
                  <img src="/assets/img/samples/partner-carousel-img-03.png" alt=""/>
                </li>
              </ul>
            </div>
          </section>

        </div>
      </div>
    </div>
  </div>
</div>
   );
}

export default MenuPanel;