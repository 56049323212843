import React, { useState, useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
// import TokpedIcon from '../icons/tokpedsvg.svg'

const Footer = (props) => {
  let datax = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
          companyName
          waNum
          email
          instaStoreUrl
          address
        }
      }
      markdownRemark(frontmatter: { id: { eq: "home" } }) {
        frontmatter {
          templateKey
          logo
          companyName
          waNum
          waMessage
          email
          instaStoreUrl
          companyAddress
        }
      }
    }
  `);
  let data = datax.site.siteMetadata;
  let siteData = datax.markdownRemark.frontmatter;
  console.log("siteDatea", siteData);
  return (
    <>
      <footer id="footer" class="footer text-center">
        <ul class="social-menu social-menu--landing social-menu--landing-glitch">
          <li>
            <a href="https://tokopedia.com/apexwarrior" target="_blank">
            <i class="fas fa-book-open"></i>
              <span class="link-subtitle">Store</span>Tokopedia
            </a>
          </li>
          <li>
            <a href="https://shopee.co.id/apexwarrior" target="_blank">
              <i class="fab fa-shopify"></i>
              <span class="link-subtitle">Store</span>Shopee
            </a>
          </li>
          <li>
            <a href="https://instagram.com/apexwarrior.id" target="_blank">
              <i class="fab fa-instagram"></i>
              <span class="link-subtitle">Social</span>Instagram
            </a>
          </li>
          <li>
            <a href="https://wa.me/message/LGXWX5CG54JLE1" target="_blank">
              <i class="fab fa-whatsapp"></i>
              <span class="link-subtitle">Chat</span>Whatsapp
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
};

export default Footer;
