import React, { useEffect, useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import SEO from "./seo";
// require('../assets/js/vendor/vendor.min.js')
// require('../assets/js/plugins/plugins.js')
// require('../assets/js/main.js')
import Header from "./Header";
import Footer from "./Footer";
import $ from "jquery";
import MenuPanel from "./MenuPanel";
// if (typeof window !== "undefined") {
//   // eslint-disable-next-line global-require
//   require("smooth-scroll")('a[href*="#"]')
// }

const Layout = ({ location, title, children }) => {
  // const [isLoading, setIsLoading] = useState(true);
  const scriptElements = [];
  useEffect(() => {
    const scriptPaths = [
      "/azzet/js/vendor/jquery-3.1.1.min.js",
      "/assets/vendor/jquery/jquery-migrate.min.js",
      "/assets/vendor/bootstrap/js/bootstrap.bundle.min.js",
      "/assets/js/core.js",
      "//maps.googleapis.com/maps/api/js?key=AIzaSyBJJXmih9TuQ0Xt0UgB7WCK1xEk9q09kKE",
      "/assets/vendor/gmap3/gmap3.min.js",
      "/assets/js/init.js",
      "/assets/vendor/particleground/particleground.js",
      "/assets/js/custom.js",
    ];

    $(() => {
      scriptPaths.forEach((src, index) => {
        let script = document.createElement("script");
        script.src = src;
        script.type = "text/javascript";
        script.className = `main-script-${index}`;
        document
          .querySelectorAll(`.main-script-${index}`)
          .forEach(function (a) {
            a.remove();
          });

        scriptElements.push(script);

        if (index == 0) {
          document.body.appendChild(script);
        } else {
          scriptElements[index - 1].onload = function () {
            console.log("onload fired for script " + index);
            document.body.appendChild(script);
          };
        }

        // if(scriptElements.length == scriptPaths.length) {
        //   console.log('check me out', scriptElements)
        //   scriptElements[scriptElements.length - 1].onload = () => {
        //     console.log("set laoding to false");
        //     setIsLoading(false);
        //   };
        // }
      });
      console.log("WHERE IS JQUERY", window.jQuery, window);
      // scriptElements[scriptElements.length - 1].onload = () => {
      //   setIsLoading(false);
      // };
    });
  }, []);

  // console.log('check me out', scriptElements)
  // if(scriptElements.length != 0)
  //   scriptElements[scriptElements.length - 1].onload = () => {
  //     setIsLoading(false);
  //   };

  // const data = useStaticQuery(graphql`
  //   query LayoutQuery {
  //     markdownRemark(frontmatter: { id: { eq: "home" } }) {
  //       frontmatter {
  //         logo
  //         id
  //       }
  //     }
  //   }
  // `)
  // const layout = data.markdownRemark.frontmatter.layout

  const data = useStaticQuery(graphql`
    query ContactQuery {
      site {
        siteMetadata {
          title
        }
      }
      markdownRemark(frontmatter: { id: { eq: "contact" } }) {
        frontmatter {
          zomatoLink
          id
          instagramLink
          whatsappLink
          whatsappNumber
          companyName
          address
          phoneNumber
        }
      }
      layout: markdownRemark(frontmatter: { id: { eq: "home" } }) {
        frontmatter {
          logo
          id
        }
      }
    }
  `);
  console.log("data", data);

  const rootPath = `${__PATH_PREFIX__}/`;

  // if (isLoading)
  //   return (
  //     <div className="container" style={{
  //       width: "100vw",
  //       height: "100vh",
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center"
  //     }}>
  //       <img style={{height: "150px"}} src="/azzet/img/loading-ripple-min.gif" />
  //     </div>
  //   );
  // else
  return (
    <>
      <SEO />
      <div
        class={`site-wrapper${
          /contact|about/.test(location.pathname) || location.pathname.startsWith('/product_') || location.pathname.startsWith('/blog_') ? " site-layout--default" : ""
        }`}
      >
        {/* <Header
          waUrl={data.markdownRemark.frontmatter.whatsappLink}
          waNumber={data.markdownRemark.frontmatter.whatsappNumber}
          pathname={location.pathname}
          logoSrc={data.layout.frontmatter.logo}
          location={location}
        /> */}
        {/* <div id="content" class="site-content" tabindex="-1">
        <div class="col-full">
          <div id="primary" class="content-area">
            <main id="main" class="site-main">
              {children}
            </main>
          </div>
        </div>
      </div> */}
        {children}
        <div class="site-overlay"></div>
        <MenuPanel />
        <div class="preloader-wrapper">
          <div class="preloader">
            <div class="lds-grid">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
