import React, { Component } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { useMediaQuery } from "react-responsive";
import { navLinks } from '../utils/navLinks'


const Header = (props) => {
  const isIphoneX = useMediaQuery({ query: "(max-width: 375px)" });

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      linktree:markdownRemark(frontmatter: { id: { eq: "linktree" } }) {
        frontmatter {
          id
          links {
            label
            url
          }
        }
      }
      markdownRemark(frontmatter: { id: { eq: "home" } }) {
        frontmatter {
          logo
          pictureLogo
          brands {
            id
            name
          }
          tags {
            id
            name
          }
        }
      }
    }
  `);
  // console.log(
  //   "tex",
  //   data.markdownRemark.frontmatter,
  //   data.linktree.frontmatter,
  //   props.location.path,
  //   props.location
  // );
  // let brands = data.markdownRemark.frontmatter.brands
  // let tags = data.markdownRemark.frontmatter.tags
  // let ecommerce = data.linktree.frontmatter.links
  // console.log('categories', tags)
  return (
    <header id="header" class="site-header site-header--landing">

    <nav class="main-nav">
      <ul class="main-nav__list">
        <li class=""><Link to="/products">Home</Link></li>
        <li class="">
          <Link to="/products">Products</Link>
          <ul class="main-nav__sub">
            <li class="">
              <Link to="/products/tag=gchair">Gaming Chair</Link>
            </li>
          </ul>
        </li></ul>
    </nav>

    <div class="header-logo header-logo--img">
    <Link to="/">
          <img
            src={data.markdownRemark.frontmatter.logo}
            srcset={data.markdownRemark.frontmatter.logo}
            alt="Apex Warrior Logo"
          />
        </Link>
    </div>

    <nav class="main-nav">
      <ul class="main-nav__list">
        <li class=""><Link to="/blogs">Articles</Link>
          <ul class="main-nav__sub">
            <li class=""><Link to="/blogs">All Articles</Link></li>
            <li class=""><Link to="/blogs">Kenapa Kita?</Link></li>
            <li class=""><Link to="/blogs">Ergonomics</Link></li>
          </ul>
        </li>
        <li class=""><Link to="/about">About Us</Link></li>
        <li class=""><Link to="/contact">Contact Us</Link>
          <ul class="main-nav__sub">
            <li class=""><Link to="/contact">Contact Page</Link></li>
            <li class=""><a href="https://wa.me/message/LGXWX5CG54JLE1">Whatsapp</a></li>
          </ul>
        </li>
      </ul>
    </nav>
  </header>
  );
};

export default Header;
